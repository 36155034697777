import styled from 'styled-components';

export const FormContainer = styled.form`
    margin-top: 3%;
    width: 600px;
    height: 200px;

    @media screen and (max-width: 800px) {
        width: 100%;
        margin-bottom: 30%;
    }
`;

export const FormFileds = styled.fieldset`
    border: 3px solid black;
`;