import React from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import { 
    CheckoutPageContainer, 
    HeaderCheckoutContainer, 
    HeaderBlock,
    TotalCheckout,
   } from './checkout.styles';

import { selectCartItems, selectCartTotal } from '../../redux/cart/cart.selector';

import CheckoutItem from "../../componets/checkout-item/checkout-item.component";
import StripeCheckout from '../../componets/stripe-form/stripe-form.component';

const CheckoutPage = ({cartItems, total}) => {
    return (
        <CheckoutPageContainer>
            <HeaderCheckoutContainer>
                <HeaderBlock>
                    <span>Produkt</span>
                </HeaderBlock>
                <HeaderBlock>
                    <span>Opis</span>
                </HeaderBlock>
                <HeaderBlock>
                    <span>Količina</span>
                </HeaderBlock>
                <HeaderBlock>
                    <span>Cena</span>
                </HeaderBlock>
                <HeaderBlock>
                    <span>Odstrani</span>
                </HeaderBlock>
            </HeaderCheckoutContainer>
            {
                cartItems.map(cartItem =>
                    <CheckoutItem key={cartItem.id} cartItem={cartItem}/>
                )
            }
            <TotalCheckout>Skupaj: {total} €</TotalCheckout>
            {
            total === 0 ?  
                null : 
            <StripeCheckout price={total} cartItems={cartItems} />
            }
        </CheckoutPageContainer>
    )
}

const mapStateToProps = createStructuredSelector({
    cartItems: selectCartItems,
    total: selectCartTotal
})

export default connect(mapStateToProps)(CheckoutPage);