import React from 'react';
import { connect } from 'react-redux';

import { selectStaticPage } from '../../redux/static-pages/static-pages.selectors';

import { 
    StaticPageContainer,
    DescriptionStaticPage
 } from './static-page.styles'

const StaticPage = ({ pages }) => {
    const { title, description } = pages;
    
    return (
        <StaticPageContainer>
            <h5>{title}</h5>
            <DescriptionStaticPage 
            dangerouslySetInnerHTML={{__html: description}}>
            </DescriptionStaticPage>
        </StaticPageContainer>
    )
}

const mapStateToProps = (state, ownProps) => ({
    pages: selectStaticPage(ownProps.match.params.staticPagesId)(state)
})

export default connect(mapStateToProps)(StaticPage);
