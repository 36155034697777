import styled from 'styled-components';

import CustomButton from '../custom-button/custom-button.component'

export const CookiesModalContainer = styled.div`
    position: fixed;
    width: 100%;
    height: 10%;
    bottom: 0;
    background-color: rgba(0,0,0,0.5);
    z-index: 2; 

    @media screen and (max-width: 800px) {
        height: 20%
    }
`;

export const ContentContainer = styled.div`
    margin: 1% auto;
    width: 70%
`;

export const TextCookiesModal = styled.div`
    font-size: 20px;
    color: white;
    text-align: center;
`;

export const ButtonCookiesModal = styled(CustomButton)`
    display: inline;
    margin-left: 10px
`;