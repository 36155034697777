import React, { useState } from 'react';
import { connect } from 'react-redux'

import { registerStart } from '../../redux/user/user.actions'

import { SignUpContainer, TitleContainer } from './sign-up.styles';

import CustomButton from '../custom-button/custom-button.component';
import FormInput from '../form-input/form-input.component';

const SignUp = ({ registerStart }) => {

    const [formData, setFormData] = useState({
        displayName: '',
        email: '',
        password: '',
        confirmPassword: ''
    });

    const {
        displayName, 
        email, 
        password, 
        confirmPassword 
    } = formData;

    const handleChange = e => {
        const { value, name } = e.target;

        setFormData({...formData, [name]: value});
    };
    
    const handleSubmit = e => {
        e.preventDefault();

        const { 
            displayName, 
            email, 
            password, 
            confirmPassword 
        } = formData;

        if( password !== confirmPassword ) {
            alert('Passwords do not match.');
            return;
        }
        registerStart({email, password, displayName})
    };

    return (
        <SignUpContainer>
            <TitleContainer>Nisem še registriran/a.</TitleContainer>
            <span>Registriraj se z e-mail-om in geslom.</span>
            <form onSubmit={handleSubmit}>
                <FormInput 
                type='text'
                name='displayName'
                value={displayName}
                onChange={handleChange}
                label='Ime in Priimek'
                required
                />
                <FormInput 
                type='text'
                name='email'
                value={email}
                onChange={handleChange}
                label='Email'
                required
                />
                <FormInput 
                type='password'
                name='password'
                value={password}
                onChange={handleChange}
                label='Geslo'
                required
                />
                <FormInput 
                type='password'
                name='confirmPassword'
                value={confirmPassword}
                onChange={handleChange}
                label='Potrdi geslo'
                required
                />
                <CustomButton type="submit">Registriraj se</CustomButton>
            </form>  
        </SignUpContainer>
    )
}

const mapDispatchToProps = dispatch => ({
    registerStart: userCredentials => dispatch(registerStart(userCredentials))
});

export default connect(null, mapDispatchToProps)(SignUp);