import React from 'react';

import { 
    CartItemContainer, 
    ItemDetailsContainer, 
    NameItemDetails, 
    PriceItemDetails 
} from './cart-item.styles'; 

const CartItem = ({item: {imageUrl, name, price, quantity}}) => {
    return (
        <CartItemContainer>
            <img src={imageUrl} alt='item-cart'/>
            <ItemDetailsContainer>
                <NameItemDetails>{name}</NameItemDetails>
                <PriceItemDetails>{quantity} x {price}€</PriceItemDetails>
            </ItemDetailsContainer>
        </CartItemContainer>
    )
}


export default CartItem;




