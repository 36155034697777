import React from 'react';
import { connect } from 'react-redux';

import { selectCollection } from '../../redux/shop/shop.selectors';

import CollectionItem from '../../componets/collection-item/collection-item.component';

import { 
    CollectionPageContainer, 
    TitleCollectionPage,
    ItemsCollectionPage 
} from './collection.styles';

const CollectionPage = ({ collection }) => {
    const { titleSlo, items } = collection;
    return (
        <CollectionPageContainer>
            <TitleCollectionPage>{ titleSlo }</TitleCollectionPage>
            <ItemsCollectionPage>
                {
                    items.map(item => (
                        <CollectionItem key={item.id} item={item}/>
                        )
                    )
                }
            </ItemsCollectionPage>       
        </CollectionPageContainer>
    )
}

const mapStateToProps = (state, ownProps) => ({
    collection: selectCollection(ownProps.match.params.collectionId)(state),
});

export default connect(mapStateToProps)(CollectionPage);