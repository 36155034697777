import React from 'react';

import { LinkToStaticPagesContainer, LinkToStaticPagesTitle } from './link-static-pages.styles'

const LinkStaticPages = ({ linkUrl, title }) => {
    return (
        <LinkToStaticPagesContainer>
            <LinkToStaticPagesTitle to={`/${linkUrl}`}>
                <small>{title}</small>
            </LinkToStaticPagesTitle>
        </LinkToStaticPagesContainer>
    )
}

export default LinkStaticPages;
