import React from 'react';
import { Link } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebookSquare, faInstagramSquare } from '@fortawesome/free-brands-svg-icons';


import { FollowUsContainer } from './follow-us.styles.js';


const FollowUs = () => {
    return (
        <FollowUsContainer>
            <p>follow us on / sledi nam na</p>
            <a href="https://www.facebook.com/BarutBrew"><FontAwesomeIcon icon={ faFacebookSquare } size='2x' /></a>
            <a href="https://www.instagram.com/barutbrew"><FontAwesomeIcon icon={ faInstagramSquare} size='2x' /></a>
        </FollowUsContainer>
    )
}

export default FollowUs;
