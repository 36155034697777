import React, { useState } from 'react';
import { Elements, CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import {loadStripe} from '@stripe/stripe-js';
import axios from 'axios';

import { FormContainer, FormFileds } from './stripe-form.styles';
import StripeInput from '../stripe-input/stripe-input.component';
import StripeSubmitButton from '../stripe-submit-button/stripe-submit-button.component';
import StripeResetButton from '../stripe-reset-button/stripe-reset-button.component';
import StripeErrorMessage from '../stripe-error-message/stripe-error-message.component';


const StripeForm = ({ price, cartItems }) => {
    const stripeAmount = price * 100;
    const stripe = useStripe();
    const elements = useElements();
    const [error, setError] = useState(null);
    const [cardComplete, setCardComplete] = useState(false);
    const [processing, setProcessing] = useState(false);
    const [paymentMethod, setPaymentMethod] = useState(null)
    const [billingDetails, setBillingDetails] = useState({
        email: '',
        name: '',
    });
    const [address, setAddress] = useState({
        line1: '',
        city: '',
        country: ''
    });

    //description for Stripe
    const descArr = cartItems.map(ci => {
        return `Paket ${ci.name} ${ci.price}€ x ${ci.quantity}.`;
    })
    const stringDesc = descArr.join(" ")


    const handleSubmit = async(e) => {
        e.preventDefault();

        if(!stripe || !elements) {
            //prevents form from submiting if Stripe is not loaded!
            return;
        }

        if(error) {
            elements.getElement('card').focus();
            return
        }

        if(cardComplete){
            setProcessing(true);
        }
        
        try {
        const res = await axios.post('/payment', {
            amount: stripeAmount,
            description: stringDesc
        });
        const clientSecret = res.data.clientSecret;
        console.log(clientSecret)
        
        const cardElement = elements.getElement(CardElement);

        //Use your card Element with other Stripe.js API
        const payload = await stripe.confirmCardPayment(clientSecret,{
            payment_method:{
                card: cardElement,
                billing_details: {
                    email: billingDetails.email,
                    name: billingDetails.name,
                    address:{
                        line1: address.line1,
                        city: address.city,
                        country: address.country
                    }
                }
            }
        });
        console.log(payload)
        setProcessing(false);

        if(payload.error) {
            setError(payload.error)
        } else {
           setPaymentMethod(payload.paymentIntent.id);
           
        }
    } catch(error) {
            console.log(error)
        }
    };
    
    const reset = () => {
        window.location.reload();
      };

    return paymentMethod ? (
        <div className="Result">
            <div className="ResultTitle" role="alert">
            Payment successful / Plačilo uspešno
            </div>
            {/* <div className="ResultMessage">
            Thanks for trying Stripe Elements. No money was charged, but we
            generated a PaymentMethod: {paymentMethod.id}
            </div> */}
            <StripeResetButton onClick={reset}/>
        </div>
    ) : ( 
        <FormContainer onSubmit={handleSubmit}>
            <FormFileds>
                <StripeInput 
                    label="Ime"
                    id="name"
                    type="text"
                    placeholder="ime in priimek"
                    required
                    autoComplete="name"
                    value={billingDetails.name}
                    onChange={(e) => {
                    setBillingDetails({...billingDetails, name: e.target.value});
                    }}
                />
                <StripeInput 
                    label="Email"
                    id="email"
                    type="text"
                    placeholder="email"
                    required
                    autoComplete="email"
                    value={billingDetails.email}
                    onChange={(e) => {
                    setBillingDetails({...billingDetails, email: e.target.value});
                    }}
                />
                <StripeInput 
                    label="Naslov"
                    id="line1"
                    type="text"
                    placeholder="naslov"
                    required
                    autoComplete="address"
                    value={address.line1}
                    onChange={(e) => {
                    setAddress({...address, line1: e.target.value});
                    }}
                />
                <StripeInput 
                    label="Mesto"
                    id="city"
                    type="text"
                    placeholder="mesto"
                    required
                    autoComplete="city"
                    value={address.city}
                    onChange={(e) => {
                    setAddress({...address, city: e.target.value});
                    }}
                />
                <StripeInput 
                    label="Država"
                    id="country"
                    type="text"
                    placeholder="država"
                    required
                    autoComplete="country"
                    value={address.country}
                    onChange={(e) => {
                    setAddress({...address, country: e.target.value});
                    }}
                />
            </FormFileds>
            <FormFileds>
                <CardElement 
                    onChange={(e) => {
                    setError(e.error);
                    setCardComplete(e.complete)
                    }}
                />
            </FormFileds>
            { error && <StripeErrorMessage>{error.message}</StripeErrorMessage>}
            <StripeSubmitButton processing={processing} error={error} disabled={!stripe}>
                Pay / Plačaj {price}€
            </StripeSubmitButton>
        </FormContainer>
    )
}



const StripeCheckout = ({ price, cartItems }) => {
    const stripePromise = loadStripe('pk_live_51I1CZZG2Q2lb5PfcjfbcSWIKSGxsOZcJIQUH2Sb2opBXzU6S6cCp8PwUkYBgkQMU8kU012xNJoBMt0mwPlKibcqm00UQ3CkcMa');
    return (
        <Elements stripe={stripePromise}>
            <StripeForm price={price} cartItems={cartItems}/>
        </Elements>
    )
}

export default StripeCheckout;
