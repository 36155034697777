import styled from 'styled-components';
import CustomButton from '../custom-button/custom-button.component';

export const CollectionItemContainer = styled.div`
  width: 22vw;
  display: flex;
  flex-direction: column;
  height: 750px;
  align-items: center;
  position: relative;

  &:hover {
    .image {
      opacity: 0.8;
    }
    button {
      opacity: 0.85;
      display: flex;
    }
  }

  @media screen and (max-width: 800px) {
    width: 100%;

    &:hover {
      .image {
        opacity: unset;
      }
      button {
        opacity: unset;
      }
    }
  }
`;

export const AddButton = styled(CustomButton)` 
  width: 80%; 
  opacity: 0.7; 
  position: absolute; 
  top: 100px;
  display: none;

@media screen and (max-width: 800px) {
    display: block;
    opacity: 0.9;
    min-width: unset;
    padding: 0 10px
  } 
`;

export const BackgroundImage = styled.div`
  width: 100%;
  height: 375px;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  margin-bottom: 5px;
  background-image: ${({ imageUrl }) => `url(${imageUrl})`};
  border: 1px solid;
  border-style: none none solid none;
`;

export const CollectionFooterContainer = styled.div`
  width: 100%;
  height: 5%;
  display: flex;
  justify-content: space-between;
  font-size: 18px;
`;

export const NameContainer = styled.span`
  width: 85%;
  margin-bottom: 15px;
`;

export const PriceContainer = styled.span`
  width: 15%;
  text-align: right;
  margin-left: 2%;
`;

export const DescriptionContainer = styled.p`
  margin-top: 10px;
  font-family: 'Oswald', sans-serif;
  font-weight: 400;
  text-align: justify;,
`;