import styled, { css } from 'styled-components';

const NamePriceQunatityStyled = css`
    width: 23%;
`;

export const CheckoutItemContainer = styled.div`
    width: 100%;
    display: flex;
    min-height: 100px;
    border-bottom: 1px solid darkgrey;
    padding: 15px 0;
    font-size: 20px;
    align-items: center;
`;

export const ImageCheckoutItemContainer = styled.div`
    width: 23%;
    padding-right: 15px;
`;

export const ImageCheckoutItem = styled.img`
    width: 100%;
    height: 100%;
`;

export const NameCheckoutItem = styled.span`
    ${NamePriceQunatityStyled}
`;

export const QuantityCheckoutItem = styled.span`
    ${NamePriceQunatityStyled}
    display: flex;
`;

export const ValueCheckoutItem = styled.span`
    margin: 0 10px;
`;

export const ArrowCheckoutItem = styled.div`
    cursor: pointer;
`;

export const PriceCheckoutItem = styled.span`
    ${NamePriceQunatityStyled}
`;

export const RemoveButtonCheckoutItem = styled.div`
    padding-left: 12px;
    cursor: pointer;
`;