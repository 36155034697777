import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import { selectDirectorySections } from '../../redux/directory/directory.selectors';
import { selectAge, selectCookies } from '../../redux/cart/cart.selector';

import MenuItem from '../menu-item/menu-item.component';
import AgePopup from '../age-pop-up/age-pop-up.component';
import CookiesModal from '../cookies-modal/cookies-modal.component';

import { DirectoryMenuContainer } from './directory.styles';


const Directory = ({ sections, age, cookies }) => {
    return (
        <Fragment>
                {
                    age ? null : <AgePopup></AgePopup>      
                }
            <DirectoryMenuContainer>
                {
                    sections.map(({ id, ...otherSectionProps }) => (
                        <MenuItem key={id} 
                        {...otherSectionProps}/>
                    ))
                }
            </DirectoryMenuContainer>
                {
                    cookies ? null : <CookiesModal></CookiesModal>
                }
        </Fragment>
    )
}

const mapStateToProps = createStructuredSelector({
  sections: selectDirectorySections,
  age: selectAge,
  cookies: selectCookies
});

export default connect(mapStateToProps)(Directory);
