import React from 'react';
import { Route } from 'react-router-dom';

import Contact from '../contact/contact.component';
import StaticPage from '../../componets/static-page/static-page.component';

import { StaticPagesContainer } from './static-pages.styles';


const StaticPagesPage = ({ match }) => {
    return (
        <StaticPagesContainer>
            <Route 
                exact path={`${match.path}`} 
                component={Contact}
            />
            <Route 
                path={`${match.path}/:staticPagesId`}
                component={StaticPage}
            />
        </StaticPagesContainer>
    )
}

export default StaticPagesPage;