import { takeLatest, put, all, call } from "redux-saga/effects";

import UserActionTypes from './user.types';

import { 
    signInSuccess, 
    signInFailure,
    signOutSuccess,
    signOutFailure,
    registerFailure,
    registerSuccess
} from './user.actions';

import { 
    auth, 
    googleProvider, 
    createUserProfileDocument,
    getCurrentUser,
} from '../../firebase/firebase.utils';

export function* getSnapshotFromUserAuth(userAuth, additionalData) {
    try {
        const userRef = yield call(createUserProfileDocument, userAuth, additionalData);
        const userSnapshot = yield userRef.get();
        yield put(
            signInSuccess({
                id: userSnapshot.id, 
                ...userSnapshot.data()
            })
        );
    } catch (error) {
        yield put(signInFailure(error));
    }
};

export function* signInWithGoogle() {
    try {
        //gets you userRef
        const { user } = yield auth.signInWithPopup(googleProvider);
        yield getSnapshotFromUserAuth(user);
    } catch (error) {
        yield put(signInFailure(error));
    }
};

export function* signInWithEmail({ payload: { email, password } }) {
    try {
        const { user } = yield auth.signInWithEmailAndPassword(email, password);
        yield getSnapshotFromUserAuth(user);
    } catch (error) {
        yield put(signInFailure(error));
    }
};

export function* isUserAuthenticated() {
    try {
        const userAuth = yield getCurrentUser();
        if(!userAuth) return;
        yield getSnapshotFromUserAuth(userAuth);
    } catch (error) {
        yield put(signInFailure(error))
    }
};

export function* signOut() {
    try {
        yield auth.signOut();
        yield put(signOutSuccess())
    } catch(error) {
        yield put(signOutFailure(error))
    }
};

export function* registerUser({payload: {email, password, displayName}}) {
    try {
        const { user } = yield auth.createUserWithEmailAndPassword(email, password)
        yield put(registerSuccess({ user, additionalData: { displayName } }))
    } catch (error) {
        yield put(registerFailure(error))
    }
};

export function* signInAfterRegister({payload: {user, additionalData}}) {
    try {
        yield getSnapshotFromUserAuth(user, additionalData) 
    } catch (error) {
        yield put(signInFailure(error));
    }  
};

export function* onGoogleSignInStart() {
    yield takeLatest(UserActionTypes.GOOGLE_SIGN_IN_START, signInWithGoogle);
};

export function* onEmailSignInStart() {
    yield takeLatest(UserActionTypes.EMAIL_SIGN_IN_START, signInWithEmail);
};

export function* onCheckUserSession() {
    yield takeLatest(UserActionTypes.CHECK_USER_SESSION, isUserAuthenticated);
};

export function* onSignOutStart() {
    yield takeLatest(UserActionTypes.SIGN_OUT_START, signOut)
};

export function* onRegisterStart() {
    yield takeLatest(UserActionTypes.REGISTER_USER_START, registerUser)
};

export function* onRegisterSuccess() {
    yield takeLatest(UserActionTypes.REGISTER_USER_SUCCESS, signInAfterRegister)
};

export function* userSagas() {
   yield all([
        call(onGoogleSignInStart), 
        call(onEmailSignInStart),
        call(onCheckUserSession),
        call(onSignOutStart),
        call(onRegisterStart),
        call(onRegisterSuccess)
    ]);
};

