import React from 'react';
import { PayButton } from './stripe-submit-button.styles';

const StripeSubmitButton = ({processing, error, children, disabled}) => {
    return (
        <div>
        <PayButton
        className={`StripeSubmitButton ${error ? 'StripeSubmitButton--error' : ''}`}
        type='submit'
        disabled={processing || disabled}>
            {processing ? 'Processing...' : children} 
        </PayButton>
        </div>
    )
}

export default StripeSubmitButton;
