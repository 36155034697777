import React from 'react';
import { connect } from 'react-redux';

import { clearItemFromCart, addItem, removeItem } from '../../redux/cart/cart.actions';

import { 
    CheckoutItemContainer, 
    ImageCheckoutItemContainer,
    ImageCheckoutItem, 
    NameCheckoutItem, 
    QuantityCheckoutItem, 
    ValueCheckoutItem, 
    ArrowCheckoutItem, 
    PriceCheckoutItem,
    RemoveButtonCheckoutItem 
} from './checkout-item.styles';

const CheckoutItem = ({ cartItem, clearItem, removeItem, addItem }) => { 
    const { name, imageUrl, price, quantity } = cartItem;

    return (
        <CheckoutItemContainer>
            <ImageCheckoutItemContainer>
                <ImageCheckoutItem src={imageUrl} alt='item' />
            </ImageCheckoutItemContainer>
            <NameCheckoutItem>{name}</NameCheckoutItem>
            <QuantityCheckoutItem>
                <ArrowCheckoutItem onClick={() => removeItem(cartItem)}>&#10094;</ArrowCheckoutItem>
                <ValueCheckoutItem>{quantity}</ValueCheckoutItem>
                <ArrowCheckoutItem onClick={() => addItem(cartItem)}>&#10095;</ArrowCheckoutItem>
            </QuantityCheckoutItem>
            <PriceCheckoutItem>{price}</PriceCheckoutItem>
            <RemoveButtonCheckoutItem onClick={() => clearItem(cartItem)}>&#10008;</RemoveButtonCheckoutItem>
        </CheckoutItemContainer>
    )
}


const mapDispathToProps = dispatch => ({
    clearItem: item => dispatch(clearItemFromCart(item)),
    addItem: item => dispatch(addItem(item)),
    removeItem: item => dispatch(removeItem(item))
});

export default connect(null, mapDispathToProps)(CheckoutItem);
