import React from 'react';

import { GroupContainer, GroupFormInput, FormInputLabel } from './form-input.styles'

const FormInput = ({onChange, label, ...otherProps}) => {
    return (
        <GroupContainer>
            <GroupFormInput onChange={onChange} {...otherProps} />
            {
                label ? (
                <FormInputLabel className={otherProps.value.length ? 'shrink' : ''}>
                    {label}
                </FormInputLabel>
                ) 
                : null
            }
        </GroupContainer>
    )
}

export default FormInput;
