import CartActionTypes  from './cart.types';
import { addItemToCart, removeItemFromCart } from './cart.utils';

const INITIAL_STATE = {
    hidden: true,
    age: false,
    cookies: false,
    cartItems: []
};

const cartReducer = (state = INITIAL_STATE, action ) => {
    const { type, payload } = action; 

    switch(type){
        case CartActionTypes.TOGGLE_CART_HIDDEN: 
        return {
            ...state,
            hidden: !state.hidden
        };
        case CartActionTypes.TOGGLE_AGE:
        return {
            ...state,
            age: !state.age
        };
        case CartActionTypes.TOGGLE_COOKIES:
        return {
            ...state,
            cookies: !state.cookies
        };
        case CartActionTypes.ADD_ITEM: 
        return {
            ...state,
            cartItems: addItemToCart(state.cartItems, payload)
        };
        case CartActionTypes.REMOVE_ITEM:
        return {
            ...state,
            cartItems: removeItemFromCart(state.cartItems, payload)
        }
        case CartActionTypes.CLEAR_ITEM_FROM_CART:
        return {
            ...state,
            cartItems: state.cartItems.filter(cartItem =>
                cartItem.id !== payload.id
            )
        };
        case CartActionTypes.SET_CART_FROM_FIREBASE:
            return {
                ...state,
                cartItems: payload
            }
        case CartActionTypes.CLEAR_CART:
            return {
                ...state,
                cartItems: []
            }
        default:
            return state; 
    }
}

export default cartReducer;