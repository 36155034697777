import React from 'react';
import { withRouter } from 'react-router-dom';

import { MenuItemContainer, MenuItemBackgroundImage, MenuItemContentSubtitle, MenuItemContentTitle, MenuItemContentContainer} from './menu-item.styles'

const MenuItem = ({ title, imageUrl, size, history, linkUrl, match }) => {
    return (
        <MenuItemContainer 
        large={size} 
        onClick={() => history.push(`${match.url}${linkUrl}`)}>
            <MenuItemBackgroundImage
                imageUrl={imageUrl}
            ></MenuItemBackgroundImage>
            <MenuItemContentContainer>
                <MenuItemContentTitle>{ title }</MenuItemContentTitle>
                <MenuItemContentSubtitle>NAKUPUJ</MenuItemContentSubtitle>
            </MenuItemContentContainer>
        </MenuItemContainer>
    )
}

export default withRouter(MenuItem);
