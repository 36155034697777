import React from 'react';
import { connect } from 'react-redux';

import LinkStaticPages from '../../componets/link-static-pages/link-static-pages.component';

import { createStructuredSelector } from "reselect";
import { selectStaticPagesArray,  } from '../../redux/static-pages/static-pages.selectors';

import { ContactContainer } from "./contact.styles";

const Contact = ({ pages }) => {
    return (
        <ContactContainer>
            <address>OGRINEC d.o.o. promet, trgovina in storitve<br/>
            Polčeva pot 9. 1241 Kamnik<br/>
            e-pošta: info@barutbrew.com<br/></address>
            <p>matična številka: 5566258000<br/>
            ID za DDV: SI67842275</p>
            {
                pages.map(page => <LinkStaticPages 
                    key={page.id}
                    title={page.title} 
                    linkUrl={page.linkUrl}
                ></LinkStaticPages>
                )
            } 
        </ContactContainer>
    )
}

const mapStateToProps = createStructuredSelector({ 
    pages: selectStaticPagesArray
})

export default connect(mapStateToProps)(Contact);
