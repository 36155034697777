import styled from 'styled-components';

export const FollowUsContainer = styled.div`
    width: 30%;
    height: 70px;
    display: flex;
    justify-content: space-around;
    float: right;

    @media screen and (max-width: 800px) {
        width: 100%;
    }
`;