const INITIAL_STATE = {
    sections: [
        {
          title: 'klasična piva',
          imageUrl: 'https://res.cloudinary.com/dlldysgbe/image/upload/v1605890982/BB_Barut/kletak.png',
          id: 1,
          linkUrl: 'shop/classic'
        },
        {
          title: 'divja piva',
          imageUrl: 'https://res.cloudinary.com/dlldysgbe/image/upload/v1605890981/BB_Barut/summer-snow.png',
          id: 2,
          linkUrl: 'shop/wild'
        },
        {
          title: 'premium piva',
          imageUrl: 'https://res.cloudinary.com/dlldysgbe/image/upload/v1605890981/BB_Barut/sour-gold.png',
          id: 3,
          linkUrl: 'shop/premium'
        },
        /* {
          title: 'merch',
          imageUrl: 'https://i.ibb.co/GCCdy8t/womens.png',
          size: 'large',
          id: 4,
          linkUrl: 'shop/merch'
        },
        {
          title: 'Darilni paketi',
          imageUrl: 'https://i.ibb.co/R70vBrQ/men.png',
          size: 'large',
          id: 5,
          linkUrl: 'shop/gift'
        } */
      ] 
};

const directoryReducer = (state = INITIAL_STATE, action) =>{
    switch (action.type) {
        default: 
            return state;
    }
};

export default directoryReducer;