const CartActionTypes = {
    TOGGLE_CART_HIDDEN: 'TOGGLE_CART_HIDDEN',
    ADD_ITEM: 'ADD_ITEM',
    REMOVE_ITEM: 'REMOVE_ITEM',
    CLEAR_ITEM_FROM_CART: 'CLEAR_ITEM_FROM_CART',
    TOGGLE_AGE: 'TOGGLE_AGE',
    TOGGLE_COOKIES: 'TOGGLE_COOKIES',
    SET_CART_FROM_FIREBASE: 'SET_CART_FROM_FIREBASE',
    UPDATE_CART_IN_FIREBASE: 'UPDATE_CART_IN_FIREBASE',
    CLEAR_CART: 'CLEAR_CART'
};

export default CartActionTypes;