import React from 'react';
import { CardInput } from './stripe-input.styles';

const StripeInput = ({
    label,
    id,
    type,
    placeholder,
    required,
    autoComplete,
    value,
    onChange
}) => {
    return (
        <div>
            <label htmlFor={id}>
                {label}
            </label>
            <CardInput
            id={id}
            type={type}
            placeholder={placeholder}
            required={required}
            autoComplete={autoComplete}
            value={value}
            onChange={onChange}
            >
            </CardInput>
        </div>
    )
}

export default StripeInput
