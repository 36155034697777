import React from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { withRouter } from 'react-router-dom';

import CustomButton from '../custom-button/custom-button.component';
import CartItem from '../cart-item/cart-item.component';
import { selectCartItems } from '../../redux/cart/cart.selector';
import { toggleCartHidden } from '../../redux/cart/cart.actions';


import { 
    DropDownContainer, 
    EmptyMessageDropDown, 
    CartItemsDropDown 
} from './cart-dropdown.styles';


const CartDropdown = ({ cartItems, history, dispatch }) => {
    return (
        <DropDownContainer>
            <CartItemsDropDown>
                {   
                    cartItems.length ?
                    (cartItems.map(cartItem => 
                        <CartItem key={cartItem.id} item={cartItem}/>
                    )
                    ) : (
                    <EmptyMessageDropDown>Prazna košarica.</EmptyMessageDropDown>)
                }
            </CartItemsDropDown>
            <CustomButton onClick={() => {
                history.push('/checkout');
                dispatch(toggleCartHidden());
                }}
                >
                V KOŠARICO
            </CustomButton>
        </DropDownContainer>
    )
}

const mapStateToProps = createStructuredSelector({
    cartItems: selectCartItems
})

export default withRouter(connect(mapStateToProps)(CartDropdown));