import { all, call, takeLatest, put, select } from 'redux-saga/effects';

import { 
    clearCart,
    setCartFromFirebase
} from './cart.actions';

import UserActionTypes from '../user/user.types';
import CartActionTypes from "../cart/cart.types";
import { getUserCartRef } from '../../firebase/firebase.utils';
import { selectCartItems } from "./cart.selector";
import { selectCurrentUser } from "../user/user.selector";


export function* clearCartOnSignOut() {
    yield put(clearCart());
};

export function* checkCartFromFirebase({payload: user }) {
        const cartRef = yield getUserCartRef(user.id);
        const snapshot = yield cartRef.get();
        yield put(setCartFromFirebase(snapshot.data().cartItems))
};

export function* updateCartInFirebase() {
    const currentUser = yield select(selectCurrentUser);
    if(currentUser) {
        try {
            const cartRef = yield getUserCartRef(currentUser.id);
            const cartItems = yield select(selectCartItems);
            yield cartRef.update({ cartItems })
        } catch (error) {
            console.log(error)
        }
    }
};

export function* onSignOutSuccess() {
    yield takeLatest(UserActionTypes.SIGN_OUT_SUCCESS, clearCartOnSignOut)
};

export function* onUserSignIn() {
    yield takeLatest(UserActionTypes.SIGN_IN_SUCCESS, checkCartFromFirebase)
};


export function* onCartChange() {
    yield takeLatest(
        [
        CartActionTypes.ADD_ITEM,
        CartActionTypes.CLEAR_CART,
        CartActionTypes.CLEAR_ITEM_FROM_CART
        ], 
        updateCartInFirebase
    );
};

export function* cartSagas() {
    yield all([
        call(onSignOutSuccess),
        call(onUserSignIn),
        call(onCartChange)
    ]);
};