import { Link } from 'react-router-dom';
import styled from 'styled-components';


export const LinkToStaticPagesContainer = styled.div`
    display: inline;
    margin: 10px
`;

export const LinkToStaticPagesTitle = styled(Link)`
    text-decoration-line: underline;
`;