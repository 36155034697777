import React from 'react';
import { connect } from 'react-redux';

import { toggleCookies } from '../../redux/cart/cart.actions';

import { 
    ButtonCookiesModal,
    ContentContainer,
    CookiesModalContainer, 
    TextCookiesModal,

} from './cookies-model.styles';

const CookiesModal = ({ toggleCookies }) => {
    return (
        <CookiesModalContainer>
            <ContentContainer>
                <TextCookiesModal>
                    Spletna stran za pravilno delovanje potrebuje piškotke.  
                    <span>
                        <ButtonCookiesModal
                        onClick={toggleCookies}
                        >
                            V redu
                        </ButtonCookiesModal>
                    </span>
                </TextCookiesModal>
            </ContentContainer>
        </CookiesModalContainer>
    )
}

const mapDispatchToProps = dispatch => ({
    toggleCookies: () => dispatch(toggleCookies())
});

export default connect(null, mapDispatchToProps)(CookiesModal);
