import styled, { css, keyframes } from "styled-components";

const fadeIn = keyframes`
    from   {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
`;

export const AgePopupContainer = styled.div`
    position: absolute;
    width: 400px;
    height: auto;
    display: flex;
    flex-direction: column;
    padding: 20px;
    border: 1px solid black;
    background-color: white;
    z-index: 5;
    animation: ${fadeIn} 2s;
    top: 50px;
    left: 50px;
    
    button {
        margin-top: auto;
    }
`;

export const Over18Number = styled.p`
    font-size: 36px;
    text-align: center;
    color: green;
`;

export const Over18Text = styled.p`
    text-align: center;
`;

export const OverlayAgePopupContaienr = styled.div`
    position: fixed;
    width: 100%; 
    height: 100%; 
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0,0,0,0.5);
    z-index: 3;
`;

