import React, { useEffect } from 'react';
import { GlobalStyle } from './global.styles'
import { Switch, Route, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import Homepage from './pages/homepage/homepage.component';
import ShopPage from './pages/shop/shop.component';
import CheckoutPage from './pages/checkout/checkout.component';
import Header from './componets/header/header.component';
import StaticPages from './pages/static-pages/static-pages.component';
import SignInAndSignUpPage from './pages/sign-in-and-sign-up/sign-in-and-sign-up.component';
import FollowUs from './componets/follow-us/follow-us.component';

import { selectCurrentUser } from './redux/user/user.selector';
import { checkUserSession } from './redux/user/user.actions';


const App = ({currentUser, checkUserSession}) => {
  useEffect(() => {
    checkUserSession();
    return () => {
    }
  }, [])
  return (
    <div className="App">
      
        <GlobalStyle />
        <Header />
        <Switch>
          <Route exact path='/' component={Homepage} />
          <Route path='/shop' component={ShopPage} />
          <Route path='/contact' component={StaticPages} />
          <Route exact path="/checkout" component={CheckoutPage}/>
          <Route exact path='/signin' 
          render={() => currentUser ? 
            (<Redirect to='/'/>
              ) : (
            <SignInAndSignUpPage />)} />
        </Switch>
        <FollowUs />
    </div>
  );
}

const mapStateToProps = createStructuredSelector({
  currentUser: selectCurrentUser
});

const mapDispatchToProps = dispatch => ({
  checkUserSession: () => dispatch(checkUserSession())
})

export default connect(mapStateToProps, mapDispatchToProps)(App);