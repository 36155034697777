import React from 'react';
import CustomButton from '../custom-button/custom-button.component';
import { connect } from 'react-redux';

import { AgePopupContainer, Over18Text, Over18Number, OverlayAgePopupContaienr } from './age-pop-up.styles';

import { toggleAge } from '../../redux/cart/cart.actions';

const AgePopup = ({ toggleAge }) => {
    return (
        <OverlayAgePopupContaienr>
            <AgePopupContainer>
                <Over18Text>
                    Za ogled strani je potrebna potrditev polnoletnosti. 
                </Over18Text>
                <Over18Number>18+</Over18Number>
                <CustomButton onClick={toggleAge}>
                    DA
                </CustomButton>
            </AgePopupContainer>
        </OverlayAgePopupContaienr>
    )
}

const mapDispatchToProps = dispatch => ({
    toggleAge: () => dispatch(toggleAge())
});

export default connect(null, mapDispatchToProps)(AgePopup);