import styled from 'styled-components';

export const PreviewCollectionContainer = styled.div`
    display: flex;
    flex-direction: column;
    margin-bottom: 30px;
`

export const TitleConatainer = styled.h1`
    font-size: 28px;
    margin-bottom: 25px;
    cursor: pointer;
`

export const PreviewContainer = styled.div`
    display: flex;
    justify-content: space-between;

    @media screen and (max-width: 800px) {
        display: flex;
        flex-direction: column;
        grid-gap: 15px;
    }
`