import React from 'react'
import { connect } from 'react-redux';

import { addItem } from '../../redux/cart/cart.actions';

import { AddButton, 
    CollectionItemContainer, 
    BackgroundImage, 
    CollectionFooterContainer, 
    NameContainer, 
    PriceContainer,
    DescriptionContainer
} from "./collection-item.style";


const CollectionItem = ({ item, addItem }) => {
    const {  name, price, imageUrl, description, inStock } = item;
    
    return (
        <CollectionItemContainer>
            <BackgroundImage imageUrl={imageUrl}>
            </BackgroundImage>
            <CollectionFooterContainer>
                <NameContainer>{name}</NameContainer>
                <PriceContainer>{price}€</PriceContainer>
            </CollectionFooterContainer>
            {inStock ? null : <p>&#9981; ZALOGE POŠLE</p> }
            <DescriptionContainer>{description}</DescriptionContainer>
            {inStock ? <AddButton
            onClick={() => {
                addItem(item) 
            }}
            inverted >Dodaj v košarico</AddButton> : null}
        </CollectionItemContainer>
    )
}

const mapDispatchToProps = dispatch => ({
    addItem: item => dispatch(addItem(item))
});

export default connect(null, mapDispatchToProps)(CollectionItem);
