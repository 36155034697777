const INITIAL_STATE = {
    pages: {
        terms: {
            title: 'Splošni pogoji',
            id: 1,
            routeName: 'terms',
            linkUrl: 'contact/terms',
            description: `<p>Kupec komunicira s podjetjem OGRINEC d.o.o. v zvezi s spletno trgovino BARUT Brewing & Blanding na telefonski številki 041-33-88-66 za pomoč in dodatne informacije je kupcu na voljo še elektronski naslov: info@barutbrew.com</p>
            <p>Kupec s sprejetjem naročila potrjuje, da je seznanjen in se strinja s celotnim besedilom splošnih pogojev poslovanja na spletni strani www.barutberw.com/shop.</p>
            <p><strong>SPLOŠNI POGOJI POSLOVANJA</strong>spletne trgovine BARUT Brewing & Blanding</p>
            <p>Določajo pravice in dolžnosti med lastnikom oz. prodajalca spletne trgovine www.barutberw.com/shop, ki je: </p>
            <p>OGRINEC d.o.o. promet, trgovina in storitve (v nadaljevanju ponudnik).<br/>
            Polčeva pot 9. 1241 Kamnik<br/>
            e-pošta: info@barutbrew.com<br/>
            matična številka: 5566258000<br/>
            ID za DDV: SI67842275</p>
            <br/>
            <p>in  kupcem</p>
            <p>(v nadaljnjem besedilu “kupec”)</p>
            <p>Splošni pogoji poslovanja in uporabe določajo način in pogoje delovanja spletne trgovine na spletnem mestu: www.barutberw.com/shop (v nadaljevanju: »spletna trgovina«), pravice in obveznosti uporabnikov in upravljavca spletne trgovine urejajo pravna razmerja med prodajalcem in kupcem. Splošni pogoji poslovanja in uporabe urejajo tudi posamezna pravila nakupa in dostave blaga.</p>
            <p>Splošni pogoji poslovanja in uporabe spletne trgovine so sestavljeni v skladu z Zakonom o varstvu potrošnikov (ZVPot) na podlagi priporočil GZS in mednarodnih kodeksov za poslovanje, Obligacijskim zakonikom (OZ), Zakonom o varstvu osebnih podatkov (ZVOP-1), Zakonom o elektronskih komunikacijah (ZEKom-1), Zakonom o elektronskem poslovanju (ZEPT), Zakonom o izvensodnem reševanju potrošniških sporov (ZisPRS), Zakonom o potrjevanju računov (ZDavPR), ter drugimi povezanimi predpisi.</p>
            <p>Z uporabo spletne strani izrazite svoje strinjanje s spodaj navedenimi pogoji. Če se s pogoji ne strinjate, vas prosimo, da zapustite spletno trgovino. 
            OGRINEC d.o.o. si pridržuje pravico do spremembe pogojev na spletni strani kadarkoli, brez predhodnega obvestila.</p>
            <h5>Naročila prek spletne trgovine</h5>
            <p>Kupoprodajna pogodba med prodajalcem in kupcem je v spletni trgovini sklenjena v trenutku, ko prodajalec od kupca prejme veljavno izpolnjeno in potrjeno naročilo za nakup v okviru spletnega obrazca. Od tega trenutka, so vse cene in drugi pogoji fiksirani in veljajo tako za prodajalca kot za kupca. Kot kupec velja oseba s podatki, ki jih navede ob oddaji naročila. Kupec lahko podatke in vsebino nakupa spreminja vse do zadnjega koraka, ko potrdi podatke s klikom na gumb “Oddaj moje naročilo”. </p>
            <p>Kasnejše spreminjanje podatkov o kupcu ni mogoče. Kupec odgovarja za vnešene podatke v procesu naročanja. Ponudnik ne odgovarja za morebitne napake pri dostavi zaradi napačnega naslova. Po oddaji naročila kupec prejme prek elektronske pošte potrdilo naročila. Kupec ob vstopu na spletno stran in ob oddaji naročila potrjuje, da je polnoleten (18+) in da naročenega blaga ne bo posredoval mladoletnim osebam.</p>
            <p>Kupoprodajna pogodba med prodajalcem in kupcem je v spletni trgovini sklenjena v trenutku, ko prodajalec od kupca prejme veljavno izpolnjeno in potrjeno naročilo za nakup. Od tega trenutka so vse cene in drugi pogoji končni in veljajo tako za prodajalca kot za kupca. </p>
            <p>Arhiv oddanega naročila je v elektronski obliki shranjen na strežniku prodajalca in strežniku globalne plačilne platforme Stripe, ki omogoča plačevanje prek spleta v realnem času.</p>
            <h5>Cene</h5>
            <p>Cene izdelkov veljajo za nakupe v spletni trgovini. Vse cene vsebujejo DDV, razen če je izrecno napisano drugače. Navedene cene ne vsebujejo stroškov pošiljanja in/ali stroškov drugih storitev prevoznika. Ponudnik si pridržuje pravico do spreminjanja cen. Veljavna je tista cena, ki je objavljena v trenutku zaključka in oddaje naročila. Morebitne ugodnosti, akcije in popusti, ki so navedene na spletni strani, veljajo za nakupe prek spletne trgovine le v primeru, ko je to posebej navedeno. Cena v spletni trgovini je prikazana kot redna cena brez popustov, razen, če je označeno drugače, in velja za vse uporabnike spletne trgovine.
            Cene artiklov v spletni trgovini se lahko razlikujejo od cen istih artiklov na drugih prodajnih mestih in medsebojno niso povezane
            Cene s popustom in cene na drugih prodajnih mestih izven spletne trgovine, se ne nanašajo na spletno trgovino, razen kadar je to posebej označeno v okviru posamezne promocije.
            Popusti, promocijske kode ipd. se ne seštevajo. Darilnih bonov in kod bonusov za popust ni možno menjati za gotovino.
            Cene dostave se zaračunajo glede na težo naročila in se izračunajo avtomatsko pred oddajo naročila. .</p>
            <h5>Postopek nakupa na spletni strani</h5>
            <p>Kupec izvede nakup z naslednjimi koraki:</p>
            <ul>
            <li>izbere izdelek v spletni trgovini,</li>
            <li>poljubno število izdelkov doda v spletno “košarico”,</li>
            <li>klikne na gumb “zaključek nakupa”,</li>
            <li>potrdi s klikom na “ izbira dostave”,</li>
            <li>vnese kontaktne podatke o kupcu, podatke za dostavo blaga in unovči kupon (v kolikor je ta na voljo) na strani “informacije”,</li>
            <li>izbere način dostave blaga,</li>
            <li>potrdi s klikom na “nadaljuj na izbiro plačila”,</li>
            <li>izbere način plačila,</li>
            <li>kupec lahko ponovno in še zadnjič pregleda oddane podatke in vsebino nakupa,</li>
            <li>nakup zaključi s klikom na gumb “oddaj moje naročilo”.</li>
            </ul>
            <p>Kupec ima možnost vračanja na predhodno že potrjene korake vse dokler ne potrdi nakupa s klikom na gumb “oddaj moje naročilo”. </p>
            <h5>Način plačila</h5>
            <p>Ponudnik sprejema plačila, ki jih podpira globalna plačilna platforma za plačevanje prek spleta v realnem času Stripe. Če ponudnik plačilo prejme kasneje kot v 2 delovnih dneh od potrditve plačila, lahko nakup zavrne in kupcu povrne denar na račun (kot v primeru, ko zaradi manjka zaloge blaga naročila ne more izpolniti).</p>
            <h5>Izdaja računa</h5>
            <p>Prodajalec po dostavi naročenih artiklov kupcu pošlje tudi račun in sicer v pdf formatu na e-naslov kupca.
            Na računu sta razčlenjena cena in vsi stroški v zvezi z nakupom ter podano obvestilo o pravici do odstopa od pogodbe.
            Kupec je dolžan preveriti pravilnost podatkov pred oddajo naročila oz. pred plačilom. Kasneje podanih ugovorov glede pravilnosti izdanih računov ne upoštevamo. </p>
            <h5>Odprema blaga, naročenega prek spletne strani</h5>
            <p>Dostavo po Sloveniji opravlja podjetje Pošta Slovenije in DPD d. o. o., vendar si prodajalec pridržuje pravico izbrati drugo dostavno službo, če bo s tem naročilo lahko izpolnil bolj učinkovito. Vse pošiljke so med prevozom zavarovane. Dostave s plačilom po povzetju ne omogočamo.
            Kupec se zavezuje, da bo poskrbel za prevzem blaga na dan predvidene dostave. Kupec se zavezuje, da bo blago prevzel sam ali pooblaščena polnoletna oseba. 
            Za izgubo ali poškodbe pošiljke odgovarja prevoznik. Kupec mora o poškodbi ali izgubi pošiljke obvestiti prevoznika ali ponudnika.
            Prodajalec si prizadeva, da naročeno blago odda dostavni službi isti ali naslednji delovni dan po prejetem plačilu kupca, razen v primeru višje sile. Blago bo dostavljeno v dostavnih rokih, ki jih zagotavlja dostavna služba.</p>
            
            <h5>Status naročila</h5>
            <p>Kupcu so na spletni strani vselej dostopni podatki o statusu in vsebini naročila. Prodajalec naročilo pregleda, preveri dobavljivost naročenih artiklov in naročilo potrdi oziroma z razlogom zavrne. 
            Če prodajalec kupcu ne potrdi dobavljivosti naročenih artiklov najkasneje do izteka dveh tednov od dneva oddaje naročila, lahko prodajalec naročilo kupca zavrne iz razloga, ker artikel ni na razpolago. Z dnem zavrnitve naročilo kupca preneha veljati.
            Prodajalec ne prevzema nobene odgovornosti za škodo, ki bi nastala zaradi daljših dobavnih rokov ali zaradi nedobavljenih artiklov, ki jih prodajalec nima na zalogi.
            Prodajalec v dogovorjenem roku artikel pripravi in odpošlje in o tem po elektronski pošti obvesti kupca z obvestilom o oddaji pošiljke in sledilno številko paketa. Prodajalec v elektronski pošti obvesti kupca tudi o politiki odstopa od pogodbe, kam se lahko obrne v primeru zamude pri dostavi in kam se lahko obrne v primeru pritožbe. V primeru, da se kupec pravočasno odloči za preklic naročila, mora prodajalca o tem nemudoma obvestiti po e-mailu na naslov info@barutbrew.com ali to storiti preko uporabniškega računa, ki ga je ustvaril na spletni strani. V primeru da je naročilo že odpremljeno ga je kupec dolžan vrniti na lastne stroške.</p>
            
            <h5>Pravica do odstopa potrošnika od pogodbe</h5>
            <p>Potrošnik (fizična oseba), ima pravico, da v 14 dneh od prevzema artiklov prodajalcu sporoči, da odstopa od pogodbe, ne da bi mu bilo treba navesti razlog za takšno odločitev. Odstop od pogodbe potrošnik sporoči na e-naslov prodajalca: info@barutbrew.com.
            Odstop od pogodbe ni mogoč v primeru, da je kupec pravna oseba.
            V primeru odstopa od pogodbe potrošnik prejete artikle vrne po pošti na naslov podjetja: Ogrinec d.o.o., Polčeva pot 9, 1241 Kamnik. Artikle lahko kupec vrne tudi osebno, na zgoraj navedeni naslov.
            Vrnitev prejetih artiklov podjetju v roku za odstop od pogodbe se šteje prejeto elektronsko sporočilo o odstopu od pogodbe. Potrošnik mora artikel vrniti prodajalcu nepoškodovan in v nespremenjeni količini, razen če je artikel uničen, pokvarjen, izgubljen ali se je njegova količina zmanjšala, ne da bi bil za to kriv potrošnik. Potrošnik artiklov ne sme uporabljati/uživati do odstopa od pogodbe. Potrošnik sme opraviti ogled in preizkus artiklov v obsegu, kot je to nujno potrebno za ugotovitev dejanskega stanja. Potrošnik odgovarja za zmanjšanje vrednosti blaga, če zmanjšanje posledica ravnanja, ki ni nujno potrebno za ugotovitev narave, lastnosti in delovanja blaga.
            Edini strošek, ki bremeni potrošnika v zvezi z odstopom od pogodbe, je strošek vračila artiklov (ki se v primeru pošiljanja obračunava po ceniku dostavne službe. Artikel je potrebno vrniti prodajalcu najkasneje v roku 30 dni od poslanega sporočila o odstopu od pogodbe (nakupa). </p>
            
            <h5>Potrošnik nima pravice do odstopa od pogodbe pri pogodbah, katerih predmet je artikel:</h5>
            <p>- ki je bil izdelan po natančnih navodilih potrošnika, </p>
            <p>- ki je bilo prilagojeno njegovim osebnim potrebam,  </p>
            <p>- ki zaradi svoje narave ni primerno za vračilo,  </p>
            <p>- kateremu je že potekel rok uporabe.</p>
            <h5>Odstop od pogodbe ni mogoč:</h5>
            
            <p>- za blago, ki je izdelano po natančnih navodilih potrošnika in prilagojeno njegovim osebnim potrebam;</p>
            <p>- za blago, ki zaradi svoje narave ni primerno za vračilo, </p>
            <p>- za opravljeno storitev, če podjetje pogodbo v celoti izpolni ter se je opravljanje storitve začelo na podlagi potrošnikovega izrecnega predhodnega soglasja in s privolitvijo, da izgubi pravico do odstopa od pogodbe, ko jo podjetje v celoti izpolni; </p>
            <p>- zapečatenega blaga, ki ni primerno za vračilo zaradi varovanja zdravja ali higienskih vzrokov, če je potrošnik po dostavi odprl pečat; </p>
            
            <p>Vračilo opravljenih vplačil vključno s stroški dostave (razen dodatnih stroškov zaradi izbire vrste dostave, ki ni cenovno najugodnejša ponujena standardna oblika dostave s strani podjetja) bo izvedeno takoj, ko je to mogoče, najkasneje pa v roku 14 dni od prejema sporočila o odstopu od pogodbe. Podjetje vrne prejeta plačila potrošniku z enakim plačilnim sredstvom, kot ga je uporabil potrošnik, razen če je potrošnik izrecno zahteval uporabo drugačnega plačilnega sredstva in če potrošnik zaradi tega ne nosi nobenih stroškov. </p>
            <p>Pri odstopu od pogodbe, kjer je bil koriščen bonus, koda za popust ali promocijska koda, se ta sredstva upoštevajo kot popust in se uporabniku ne vrnejo. Uporabniku se vrne le vplačani znesek. Darilni bon se pri odstopu od pogodbe upošteva kot plačilno sredstvo in vrne uporabniku kot darilni bon, vplačani znesek pa se vrne uporabniku. </p>
            <p>V izjemnih primerih, ko artikli niso vrnjeni v skladu ZVPot, lahko potrošniku ponudimo odkup artikla z ustrezno odškodnino, ki jo z zapisnikom določimo ob vračilu. Odkup z znižano vrednostjo se upošteva ob potrditvi potrošnika po elektronski pošti. Potrošnik omenjeno odkupnino koristi izključno ob naročilu drugega artikla enake ali višje vrednosti. </p>
            <p>Pravico do vračila kupnine v primeru uveljavljanja garancije in stvarnih napak natančneje urejajo določila Zakona o varstvu potroškokov (ZVPot-1). </p>
            
            <h5>Napake na blagu / poškodovano blago</h5>
            <p>Potrošnik lahko uveljavlja svoje pravice iz naslova stvarne napake, če o napaki obvesti prodajalca v roku dveh dni od dneva prejetja blaga. Potrošnik mora v obvestilu o napaki natančno opisati napako in prodajalcu omogočiti, da stvar pregleda. Prodajalec ne odgovarja za stvarne napake na blagu, ki se pokažejo po roku uporabe. Šteje se, da je napaka na stvari obstajala že v času izročitve, če se pojavi v roku šest mesecev od izročitve.
            Potrošnik, ki je pravilno obvestil prodajalca o napaki, ima pravico od prodajalca zahtevati, da:</p>
            <p>- odpravi napako na blagu ali vrne del plačanega zneska v sorazmerju z napako ali</p>
            <p>- blago z napako zamenja z novim brezhibnim ali</p>
            <p>- vrne plačani znesek.</p>
            
            <h5>Stvarne napake</h5>
            <p>- artikel nima lastnosti, ki so potrebne za njegovo normalno rabo</p>
            <p>- artikel nima lastnosti, ki so potrebne za posebno rabo, za katero jo kupec kupuje, ki pa je bila prodajalcu znana oziroma bi mu morala biti znana</p>
            <p>- artikel nima lastnosti in odlik, ki so bile izrecno ali molče dogovorjene oziroma predpisane</p>
            <p>- je prodajalec izročil artikel, ki se ne ujema z vzorcem ali modelom, razen če je bil vzorec ali model pokazan le zaradi obvestila. </p>
            
            
            <h5>Uveljavljanje stvarne napake</h5>
            <p>Kupec nas mora o morebitni stvarni napaki skupaj z natančnim opisom le-te obvestiti v zakonsko določenem roku in nam hkrati omogočiti pregled artikla. Pravico do uveljavljanja stvarne napake na artiklu natančneje urejajo določila Zakona o varstvu potrošnikov (ZVPot-1). </p>
            
            <h5>Prevzem artiklov, vrnjenih iz obravnave garancije ali stvarne napake</h5>
            
            <p>Kupec je dolžan artikel, ki ga je prodajalcu izročil v garancijsko ali drugačno obravnavo, po zaključku obravnave prevzeti. Kupec prevzame artikel na prevzemnem mestu, na katerem ga je osebno izročil prodajalcu, v primeru, da ga je poslal po pošti, pa mu bo vrnjen na naslov za dostavo. </p>
            <p>Prodajalec kupcu pošlje obvestilo o vrnitvi artikla iz obravnave ter ga pozove na prevzem artikla. Kupec je dolžan artikel prevzeti v roku 14 dni od dneva obvestila.
            Če kupec artikla ne prevzame v navedenem roku, se artikel izroči v hrambo prodajalcu na naslovu njegovega skladišča. Prodajalec hrani artikel v lastnem skladišču do poteka 6 mesecev od dneva obvestila. Prodajalec ima za to obdobje pravico do povračila vseh stroškov skladiščenja (1 €/ dan) ter pravico do povračila vseh za ohranitev artikla potrebnih stroškov. </p>
            <p>Po poteku 6 mesecev od dneva obvestila lahko prodajalec artikel proda prek spletne trgovine in doseženi znesek kupnine, po odbitku prodajnih in ostalih stroškov prodajalca, nakaže kupcu. V primeru, da je vrednost artikla neznatna, ga lahko prodajalec podari v dobrodelne namene in v tem primeru kupcu ni dolžan nobenega vračila. Prodajalec 15 dni pred iztekom 6 mesečnega roka hrambe kupca obvesti o nameravani prodaji oziroma o podaritvi artikla, po prodaji pa tudi o doseženi ceni in znesku stroškov ter o nakazilu preostanka kupnine na transakcijski račun kupca. </p>
            
            <h5>Garancija</h5>
            <p>Če informacije o garanciji ni, artikel nima garancije ali pa podatek v tem trenutku ni znan. V slednjem primeru lahko kupec kontaktira prodajalca, ki bo zagotovil ažurno informacijo. </p>
            
            <p>Artikli z odrto embalažo so bili vrnjeni in imajo lahko manjše pomanjkljivosti (poškodovana embalaža/ artikel, odprta embalaža, itd.) in gredo v nadaljno prodajo. Tip pomanjkljivosti oziroma stanje artikla sta za vsak artikel pisno navedena (tako v opisu artikla kot na računu).
            Vrnjene artikle pregledamo, zato kljub vračilu, odprti embalaži ipd. še vedno omogočajo normalno delovanje. Zaradi tega je ustrezno določeno tudi novo obdobje garancije, ki je pisno navedeno ob vsakem artiklu. V primeru nedelovanja vrnjenega artikla je stranka upravičena do garancijskega popravila in do odstopa od pogodbe v enakih rokih, kot veljajo za ostale artikle. </p>
            <h5>Obveščanje</h5>
            <p>Prodajalec bo z uporabnikom stopil v stik preko sredstev komunikacij na daljavo le, če temu uporabnik izrecno ne nasprotuje, in v skladu z določili ZEKom-1. Uporabnik svojo voljo izrazi tako, da ob oddaji podatkov pri oddaji nakupa izrecno odkljuka, da želi prejemati komercialna sporočila. </p>
            <p>Oglasna elektronska sporočila prodajalca bodo vsebovala naslednje sestavine: </p>
            <p>- jasno in nedvoumno bodo označena kot oglasna sporočila, </p>
            <p>- pošiljatelj bo jasno razviden, </p>
            <p>- različne akcije, promocije in druge tržne tehnike bodo označene kot takšne. Prav tako bodo jasno določeni pogoji udeležbe v njih,</p>
            <p>- jasno bo predstavljen način odjave od prejemanja oglasnih sporočil, </p>
            <p>- željo uporabnika, da ne želi prejemati oglasnih sporočil, bo prodajalec izrecno spoštoval. </p>
            
            <h5>Varnost</h5>
            <p>Prodajalec uporablja ustrezna tehnološka in organizacijska sredstva za zaščito prenosa in shranjevanja osebnih podatkov ter plačil. Za varne avtorizacije in transakcije s kreditnimi karticami skrbi podjetje Stripe. Avtorizacije kreditnih kartic se vršijo v realnem času s takojšnjim preverjanjem podatkov pri bankah. Podatki o karticah se ne beležijo na strežniku ponudnika.</p>
            <h5>Varstvo osebnih podatkov</h5>
            <p>Prodajalec se zavezuje k trajnemu varovanju vseh osebnih podatkov uporabnika v skladu z Uredbo (EU) 2016/679 Evropskega parlamenta in Sveta z dne 27. aprila 2016 (GDPR) in Zakonom o varstvu osebnih podatkov. Uporabnik se mora zavedati, da je dolžan prodajalcu posredovati svoje osebne podatke v pravilni in resnični obliki, prav tako je dolžan prodajalca obvestiti o spremembi svojih podatkov. Uporabnik s sprejetjem teh pogojev potrjuje, da so osebni podatki, ki jih je navedel, pravilni.</p>
            
            <h5>Omejitev odgovornosti</h5>
            <p>Prodajalec se po svojih najboljših močeh trudi zagotoviti ažurnost in pravilnost podatkov, ki so objavljeni na njegovih spletnih straneh. Kljub temu pa se lahko lastnosti artiklov, rok dobave ali cena spremenijo tako hitro, da prodajalec ne uspe popraviti podatkov na spletnih straneh. V takem primeru, bo prodajalec kupca obvestil o spremembah in mu omogočil odstop od pogodbe ali zamenjavo naročenega artikla. </p>
            
            <h5>Pritožbe in spori</h5>
            <p>Prodajalec se trudi izpolnjevati svojo dolžnost zagotavljanja učinkovitega sistema obravnavanja pritožb. V primeru kakršnihkoli težav se kupec lahko obrne na ponudnika storitve (Ogrinec d.o.o.) preko telefonske številke 041-33-88-66 ali preko elektronske pošte na info@barutbrew.com. Pritožba se odda preko elektronske pošte na info@barutbrew.com. Postopek obravnave pritožbe je zaupne narave. Ogrinec d.o.o. se zaveda, da je reševanje potrošniških sporov nesorazmernost med ekonomsko vrednostjo zahtevka in potrebnim časom ter stroški, ki nastanejo zaradi reševanja spora, kar je tudi glavna ovira, da potrošnik ne sproži spora pred sodiščem. Ogrinec d.o.o. si prizadeva za izvensodno reševanje sporov oziroma, da se spori rešijo sporazumno.</p>
            `
        },
        cookies: {
            title: 'Piškotki',
            id: 2,
            routeName: 'cookies',
            linkUrl: 'contact/cookies',
            description: `<h5>Politika uporabe spletnih piškotkov</h5>
            <p>Spletni piškotki so datoteke, ki se namestijo v brskalnik, ko obiskovalec obišče spletno stran. Sam piškotek ne zbira informacije o uporabniku. Spletna stran uporablja piškotke, kot pomoč pri izvajanju uporabniku prijaznejših storitev. Samo strežnik, ki je poslal spletni piškotek, lahko prebere in uporablja ta piškotek. Uporabnik spletne strani, ki je prejel spletni piškotek ga lahko kadarkoli izbriše iz svojega brskalnika, ga omeji ali onemogoči njegovo delovanje. Spletna stran uporablja piškotke, ki omogočajo boljšo funkcionalnost in delovanje spletne strani. Z njimi se izboljša vaša uporabniška izkušnja in piškotke za oglaševanje in trženje, ki se naložijo obiskovalcu spletne strani ob obisku te strani ali pa prihajajo s strani nekih drugih oglaševalskih mrež ali partnerskih spletnih strani.</p>
            <h5>Obvezni piškotki</h5>
            <p>Ti piškotki so nujni za premikanje po spletni strani in uporabo njenih možnosti, kot je naprimer dostop do varnih območij spletne strani. Brez teh piškotkov ni možno zagotoviti storitev, ki ste jih zahtevali, naprimer obrazca za nagradno igro ipd.</p>
            <h5>Funkcionalni piškotki</h5>
            <p>Ti piškotki omogočajo, da si spletna stran zapomni vaše izbrane možnosti (na primer vaše uporabniško ime, jezik ali regijo, kjer se nahajate) in vam ponudi izboljšane, bolj osebne možnosti. </p>
            <h5>Izvedbeni piškotki</h5>
            <p>Ti piškotki zbirajo informacije o načinu uporabe spletne strani, naprimer katere strani uporabniki najpogosteje obiščejo, in če se na spletnih straneh pojavijo sporočila o napaki. Ti piškotki ne zbirajo informacij, ki bi lahko identificirale uporabnika. Vse zbrane informacije so združene in anonimne. Ti piškotki se uporabljajo samo za izboljšanje delovanja spletne strani. </p>
            `
        }
    }
};

const staticPagesReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        default: 
            return state;
    }
}

export default staticPagesReducer;