import React from 'react'
import CollectionItem from '../collection-item/collection-item.component';

import { PreviewCollectionContainer, TitleConatainer, PreviewContainer } from './preview-collection.styles';

const PreviewCollection = ({ items, titleSlo }) => {
    return (
        <PreviewCollectionContainer>
            <TitleConatainer>{titleSlo.toUpperCase()}</TitleConatainer>
            <PreviewContainer>
                {
                items
                 .filter((item, idx) => idx < 4 )
                 .map( item => (
                    <CollectionItem key={item.id} item={item} />
                ))}
            </PreviewContainer>
        </PreviewCollectionContainer>
    )
}

export default PreviewCollection;