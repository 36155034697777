import React, { useState } from 'react';
import { connect } from 'react-redux';

import { googleSignInStart, emailSignInStart } from "../../redux/user/user.actions";

import { SignInContainer, SignInTitle, ButtonsBarContainer } from './sign-in.styles'

import FormInput from '../form-input/form-input.component';
import CustomButton from '../custom-button/custom-button.component';


const SignIn = ({ googleSignInStart, emailSignInStart }) => {
    const [formData, setFormData] = useState({
        email: '',
        password: ''
    })
    
    const { email, password } = formData;

    const onChange = e => {
        const { value, name } = e.target
        
        setFormData({...formData, [name]: value});
    }

    const onSubmit = async e => {
        e.preventDefault();
        const { email, password } = formData;
        emailSignInStart(email, password);
    }

    return (
        <SignInContainer>
            <SignInTitle>Sem že registriran/a.</SignInTitle>
            <span>Prijavi se z e-mail-om in geslom</span>
            
            <form onSubmit={onSubmit}>
                <FormInput
                name="email" 
                type='email' 
                value={email}
                onChange={onChange}
                label='Email'
                required
                /> 
                <FormInput
                type="password" 
                name="password"
                onChange={onChange}
                label='Geslo'
                value={password}
                required
                />
                <ButtonsBarContainer>
                    <CustomButton type="submit">Prijava</CustomButton>
                    <CustomButton 
                    type="button" 
                    onClick={googleSignInStart} 
                    isGoogleSignIn
                    >
                        Google prijava
                    </CustomButton>
                </ButtonsBarContainer>
            </form>
        </SignInContainer>
    )
}

const mapDispatchToProps = dispatch => ({
    googleSignInStart: () => dispatch(googleSignInStart()),
    emailSignInStart: (email, password) => dispatch(emailSignInStart({ email, password }))
});


export default connect(null, mapDispatchToProps)(SignIn);
