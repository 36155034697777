import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/auth';

const config = {
    apiKey: "AIzaSyAnVcnFPuoR5BoIr69LvYx27b2wK9FtEAw",
    authDomain: "barut-bb.firebaseapp.com",
    databaseURL: "https://barut-bb.firebaseio.com",
    projectId: "barut-bb",
    storageBucket: "barut-bb.appspot.com",
    messagingSenderId: "393321585817",
    appId: "1:393321585817:web:55aeed67e4b29588332c08",
    measurementId: "G-TPKJSN0VN6"
};

firebase.initializeApp(config);

export const createUserProfileDocument = async (userAuth, additionalData) => {
 if(!userAuth) return;

 const userRef = firestore.doc(`users/${userAuth.uid}`);

 const snapShot = await userRef.get();
 
 if(!snapShot.exists) {
     const { displayName, email } =  userAuth;
     const createdAt = new Date();

     try {
         await userRef.set({
             displayName,
             email,
             createdAt,
             ...additionalData
         });
        
     } catch(error) {
        console.log('error creating user', error.message);
     }
 }

 return userRef;
};


export const getUserCartRef = async userId => {
    const cartsRef = firestore.collection('carts').where('userId', '==', userId);
    const snapShot = await cartsRef.get()
    
    if(snapShot.empty) {
        const cartCollectionFields = { 
            userId, 
            cartItems: []
        }
        const cartDocRef = firestore.collection('carts').doc();
        cartDocRef.set(cartCollectionFields);
        return cartDocRef;
    } else {
        return snapShot.docs[0].ref;
    }
};


export const addCollectionAndDocuments = async (collectionKey, objectsToAdd) => {
    const collectionRef = firestore.collection(collectionKey);

    const batch = firestore.batch();
    objectsToAdd.forEach(obj => {
        const newDocRef = collectionRef.doc();
        batch.set(newDocRef, obj);
    });

    return await batch.commit();
};

export const convertCollectionsSnapshotToMap = (collections) => {
    const transformedCollection = collections.docs.map(doc => {
        const { title, items, titleSlo } = doc.data();

        return {
            routeName: title.toLowerCase(),
            id: doc.id,
            title,
            items,
            titleSlo
        }
    });

    return transformedCollection.reduce((accumulator, collection) => {
        accumulator[collection.title.toLowerCase()] = collection;
        return accumulator;
    }, {}); 
};

export const getCurrentUser = () => {
    return new Promise((resolve, reject) => {
        const unsubscribe = auth.onAuthStateChanged(userAuth => {
            unsubscribe();
            resolve(userAuth);
        }, reject)
    });
}

/* export const updateUserCart = async (userId, cartItems) => {
    const cartsRef = firestore.collection('carts').where('userId', '==', userId);
    const snapshot = await cartsRef.get();
    const cartRef = snapshot.docs[0].ref;

    try {
       
        cartRef.update({
            cartItems: cartItems
        });
    
    } catch(error) {
        console.log('Failed to update firebase')
    }
} */

export const auth = firebase.auth();
export const firestore = firebase.firestore();

export const googleProvider = new firebase.auth.GoogleAuthProvider();
googleProvider.setCustomParameters({ prompt: 'select_account' });
export const signInWithGoogle = () => auth.signInWithPopup(googleProvider);

export default firebase;