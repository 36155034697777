import { createSelector } from "reselect";

const selectStaticPages = state => state.staticPages;

export const selectStaticPagesPage = createSelector(
    [selectStaticPages],
    staticPages => staticPages.pages
);

//here you can add memoize == if the data is on server
export const selectStaticPage = staticPageUrlParam => 
    createSelector(
        [selectStaticPagesPage],
        page => (page ? page[staticPageUrlParam] : null)
    );

export const selectStaticPagesArray = createSelector(
    [selectStaticPagesPage],
    pages => Object.keys(pages).map(page => pages[page])
);
