import styled from 'styled-components';

export const CheckoutPageContainer = styled.div`
    width: 55%;
    min-height: 90vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 50px auto 0;

    button {
        margin-left: auto;
        margin-top: 50px;
    } 

    @media screen and (max-width: 800px) {
        width: 85%;
    }
`;

export const HeaderCheckoutContainer = styled.div`
    width: 100%;
    padding: 10px 0;
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid darkgrey;

    button {
        margin-left: auto;
        margin-top: 50px;
    }
`;

export const HeaderBlock = styled.div`
    text-transform: capitalize;
    width: 23%;

    &:last-child {
        width: 8%;
    }
    @media screen and (max-width: 800px) {
        font-size: 12px;
        width: 23%;
        &:last-child {
            width: 23%;
        }
    }
`;

export const TotalCheckout = styled.div`
    margin-top: 30px;
    margin-left: auto;
    font-size: 36px;
`;

export const TextWarning = styled.div`
    text-align: center;
    margin-top: 40px;
    font-size: 24px;
    color: red;
`;